import {Link} from 'react-router-dom';
import {auth} from '../config/firebase';
import {useAuthState} from 'react-firebase-hooks/auth'
import {signOut} from 'firebase/auth'
//import image from '../images/ace.png';
import '../App.css';
import Button from 'rsuite/Button';
import 'rsuite/Button/styles/index.css';
import LogoImage from '../images/ace.png'
import './navbar.css'

//useAuthState updates the uservariable when logged into the account
export const Navbar = () => {
    const [user] = useAuthState(auth);

    const signUserOut = async() => {
        await signOut(auth);
    }


      
    return(
        <nav className="bg-[#181818] dark:bg-[#181818]">
  <div className="max-w-screen-xl mx-auto px-4">
    <div className="flex items-center justify-between h-24"> 
      <Link to="/" className="flex-shrink-0">
        <img src={LogoImage} className="h-24" alt="ACE Logo" />
      </Link>
      
      <div className="hidden md:flex items-center space-x-8">
        <Link to="/" className="text-white dark:text-white hover:text-red-700 dark:hover:text-red-500 no-underline">Home</Link>
        <Link to="/services" className="text-white dark:text-white hover:text-red-700 dark:hover:text-red-500 no-underline">Services</Link>
        <Link to="/contactus" className="text-white dark:text-white hover:text-red-700 dark:hover:text-red-500 no-underline">Contact Us</Link>
        {!user ? (
          <>
            <Link to="/aboutus" className="text-white dark:text-white hover:text-red-700 dark:hover:text-red-500 no-underline">About Us</Link>
            <Link to="/startchapter" className="text-white dark:text-white hover:text-red-700 dark:hover:text-red-500 no-underline">Start a Chapter</Link>
          </>
        ) : (
          <>
            <Link to="/posts" className="text-white dark:text-white hover:text-red-700 dark:hover:text-red-500 no-underline">Posts</Link>
            <Link to="/createpost" className="text-white dark:text-white hover:text-red-700 dark:hover:text-red-500 no-underline">Upload</Link>
          </>
        )}
      </div>
      
      <div className="flex items-center">
        {!user ? (
          <Link to="/login" className="button-link"> <Button color="red" appearance="primary" className="login-button">Sign In</Button>

          </Link>
        ) : (
          <div className="flex items-center space-x-3">
            <p className="text-sm text-white dark:text-white">{user.displayName}</p>
            <img src={user.photoURL || ""} className="w-10 h-10 rounded-full" alt="User" />
            <Button onClick={signUserOut} color="red" appearance="primary" className="logout-button">Log Out</Button>
          </div>
        )}
      </div>
    </div>
  </div>
</nav>
  
    );
}

/**<div className="navbar">
            <div className="logo">
                <Link to= "/home">
                <img src={LogoImage} alt="ACE Logo" className="logoimage" />
                </Link>
            </div>
            
            <div className="links">
            <Link to="/home">Home</Link>
            <Link to="/services">Services</Link>
            <Link to ="/contactus">Contact Us</Link>
            {!user ? (
            <>
            <Link to ="/aboutus">About Us</Link>
            <Link to ="/startchapter">Start a Chapter</Link>
            <Link to="/login" className="button-link"> <Button color="red" appearance="primary">Login</Button></Link>
            </>
            ) : (
            <>
            <Link to="/">Posts</Link>
            <Link to ="/createpost">Upload</Link>
            </>
            )}
            </div>

            <div className="user">
                {user && (
                    <>
                <p>{user?.displayName}</p>
                <img src={user?.photoURL || ""} width="40" height="40" alt="userpfp" className="userlogo" />
                <button onClick={signUserOut} className="custom-gradient-button-pink-orange"> <span>Log Out</span></button>
                </>
)}
            </div>
        </div>

 <nav className="bg-white border-gray-200 dark:bg-[#181818]">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto py-2 px-4">
          <Link to="/home" className="flex items-center no-underline">
            <img src={LogoImage} className="h-20 md:h-24" alt="ACE Logo" />
          </Link>
          <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
            {!user ? (
              <Link to="/login">
                <Button color="red" appearance="primary" className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800">
                  Login
                </Button>
              </Link>
            ) : (
              <div className="flex items-center space-x-3">
                <p className="text-sm">{user.displayName}</p>
                <img src={user.photoURL || ""} className="w-10 h-10 rounded-full" alt="User" />
                <button onClick={signUserOut} className="text-white bg-gradient-to-r from-pink-500 to-orange-500 hover:bg-gradient-to-l focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                  Log Out
                </button>
              </div>
            )}
          </div>
          <div className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1" id="navbar-sticky">
            <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              <li>
                <Link to="/home" className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 no-underline md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Home</Link>
              </li>
              <li>
                <Link to="/services" className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 no-underline md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Services</Link>
              </li>
              <li>
                <Link to="/contactus" className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 no-underline md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Contact Us</Link>
              </li>
              {!user ? (
                <>
                  <li>
                    <Link to="/aboutus" className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 no-underline md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">About Us</Link>
                  </li>
                  <li>
                    <Link to="/startchapter" className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 no-underline md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Start a Chapter</Link>
                  </li>
                </>
              ) : (
                <>
                  <li>
                    <Link to="/" className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 no-underline md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Posts</Link>
                  </li>
                  <li>
                    <Link to="/createpost" className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 no-underline md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Upload</Link>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>**/