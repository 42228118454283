import AyanImage from '../../images/ayan.png'
import '../../App.css';
import Ryan from '../../images/ryan.png'
import BenedictImage from '../../images/benedict.png'
//import WyomingImage from '../../images/wyoming.jpg';
import ElementaryImage from '../../images/stocktutor.png'


export const AboutUs = () => {
    return(
        <div>
        <div className="flex h-screen bg-[#181818] text-white">
      <div className="flex-1 flex items-center">
        <div className="p-8 -mt-16">
          <h1 className="text-5xl font-bold font-inter text-red-500 mb-4">
            Our Mission
          </h1>
          <p className="text-lg mb-6">
            At ACE, our mission is foster an environment of collaborative learning and academic excellence. As students, we know how difficult it is to prepare 
            for rigorous tests. We strive to create comprehensive study materials that empower students to achieve their full potential. We are committed to helping
            students connect with their peers to help them succeed in their future.  
          </p>
          <p className="text-lg text-orange-400 mb-6">
            Join us in our mission to create a brighter, more connected future for students.
          </p>
          <div className="flex justify-center space-x-4">
            <a href="/startchapter"><button className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-xl">
              Join Us
            </button></a>
            <a href="/services"><button className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-4 rounded-xl">
              Explore Resources
            </button></a>
          </div>
        </div>
      </div>
      <div className="flex-1 flex items-center justify-center">
        <div className="h-3/4 w-full -mt-16">
          <img
            src={ElementaryImage}
            alt="Students collaborating"
            className="h-full w-full object-cover rounded-lg"
          />
        </div>
      </div>
    </div>
        <br />
        <div>
            <h1 className="mb-4 text-3xl font-extrabold text-gray-900 dark:text-white md:text-5xl lg:text-6xl">
            <span className="gradient-text">Meet The Team.</span> 
</h1>
            <hr className="horizontal"></hr>
        </div>
        <div className="image-grid-container">
            <div className="image-grid-row">
                <div className="team-member">
                    <img src={AyanImage} alt="Ayan" width="300" height="300" />
                    <h1>Ayan Mishra</h1>
                    <h2>Chief Executive Officer</h2>
                    <p>Ayan is a junior at Plano East Senior High School. </p>
                </div>
                <div className="team-member">
                    <img src={BenedictImage} alt="Benedict" width="300" height="300" />
                    <h1>Benedict Premilan</h1>
                    <h2>Chief Operating Officer</h2>
                    <p>Hi! My name is Benedict Premilan, and I'm junior at Plano Senior High. As a life-long learner I believe that ACE 
                        is something that can be used to share this love for learning and enrich education all across the nation. 
                        In my free time, I enjoy going to the gym and reading. </p>
                </div>
                <div className="team-member">
                        <img src={Ryan} alt="Ryan" width="300" height="300"  />
                        <h1>Ryan Thieu</h1>
                        <h2>Chief Technology Officer</h2>
                        <p>Hi! My name is Ryan Thieu and I've always shown a passion for learning and helping others; ACE helps
                        me bridge my interests together! In my free time I enjoy listening to music, programming, or watching the NFL. </p>
                </div>
            </div>
            
        </div>
        
        
    
        </div>
    );
}


/**<iframe src="https://www.google.com/maps/d/u/0/embed?mid=1XnbDyKdWzY3LSIPBKOpPfcNRTOURuls&ehbc=2E312F&noprof=1" width="640" height="480" title="acelocations"></iframe>**/

