/**import {getDocs, collection} from 'firebase/firestore'
import {db} from '../../config/firebase'
import {useState, useEffect} from 'react';
import {Post} from './post'
import './post.css'

export interface PostType {
    id: string;
    userId: string;
    title: string;
    username: string;
    description: string;
    hyperlink: string;
    pdflink: string;
    selectedCourseOption: string;
    selectedSchoolOption: string;
    selectedUnitOption: string;
}

const [updatedPostsList, setUpdatedPostsList] = useState<PostType[] | null>(null);
const updatedPostsRef = collection(db, "posts");


const getUpdatedPosts = async () => {
    const data = await getDocs(postsRef)
    setUpdatedPostsList(data.docs.map((doc) => ({...doc.data(), id: doc.id})) as PostType[]);
}

useEffect(() => {
    getUpdatedPosts()
}, [])

{updatedPostsList.map((updatedPosts, index) => (
          <option key={index} value={updatedPosts}>{updatedPosts}</option>
        ))}
 {schools.map((school, index) => (
          <option key={index} value={school}>{school}</option>
        ))}

export const Main = () => {
    const [postsList, setPostsList] = useState<PostType[] | null>(null);
    const postsRef = collection(db, "posts");
    
    const getPosts = async () => {
        const data = await getDocs(postsRef)
        setPostsList(data.docs.map((doc) => ({...doc.data(), id: doc.id})) as PostType[]);
    }
    
    useEffect(() => {
        getPosts();
    }, [])


    const [selectedSchool, setSelectedSchool] = useState('');
    const [selectedCourse, setSelectedCourse] = useState('');
    const [filteredPosts, setFilteredPosts] = useState([]);
  
    
    return(
        <div>
            <select className="dropdownselect">
                <option>School Select</option>
                <option>hello</option>
            </select>
            <select className="dropdownselect">
                <option>Course Select</option>
                <option>Oh</option>
            </select>
            <div className="rounded-lg">
            <h1>{postsList?.map((post) => (<Post key={post.id} post={post}/>))}</h1>
            </div>
        </div>
    );
}
 // Assume you have these arrays of data
  const schools = ['Plano East Senior High', 'Independence High School', 'School C'];
  const courses = ['AP Computer Science Principles', 'AP Human Geography', 'Course 3'];
**/

/**if userId == userId they can delete their own data */

import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../config/firebase'; 
import {Post} from './post'
import {useAuthState} from 'react-firebase-hooks/auth'
import {auth} from '../../config/firebase'
import './post.css'
import DogLoginImage from '../../images/dog.png'
//import { getFirestore, collection, getDocs, addDoc, deleteDoc, doc } from 'firebase/firestore';


export interface PostType {
    id: string;
    userId: string;
    title: string;
    username: string;
    description: string;
    hyperlink: string;
    pdflink: string;
    selectedCourseOption: string;
    selectedSchoolOption: string;
    selectedUnitOption: string;
}
const DefaultScreen = () =>(
    <div className="defaultscreen">
        <div className="post-home-container">
        <img src={DogLoginImage} alt="original post" className="dog-image-size"/>
        <h1 className="mb-4 text-4xl font-inter leading-none tracking-tight text-gray-900 md:text-4xl lg:text-5xl dark:text-white"><span className="gradient-text-ace">Welcome to the Posts page!</span></h1>
                <div className="/underlined">
                <p className="mb-6 text-lg font-inter text-gray-300 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">Select a school and a class to get started.</p>
                </div>
        </div>
    </div>
);

export const Main = () => {
    const [allPosts, setAllPosts] = useState<PostType[]>([]);
    const [filteredPosts, setFilteredPosts] = useState<PostType[]>([]);
    const [selectedSchool, setSelectedSchool] = useState('');
    const [selectedCourse, setSelectedCourse] = useState('');

    const [user] = useAuthState(auth);
  
    const postsRef = collection(db, "posts");
  
    useEffect(() => {
      const fetchAllPosts = async () => {
        const data = await getDocs(postsRef);
        const posts = data.docs.map((doc) => ({...doc.data(), id: doc.id})) as PostType[];
        setAllPosts(posts);
        setFilteredPosts(posts);
      };
      fetchAllPosts();
    }, []);
  
    useEffect(() => {
      const newFilteredPosts = allPosts.filter(post => 
        (!selectedSchool || post.selectedSchoolOption === selectedSchool) &&
        (!selectedCourse || post.selectedCourseOption === selectedCourse)
      );
      setFilteredPosts(newFilteredPosts);
    }, [selectedSchool, selectedCourse, allPosts]);
  
    const handleSchoolChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      const newSchool = e.target.value;
      setSelectedSchool(newSchool);
      
      // Check if the current course is valid for the new school
      const isCurrentCourseValid = allPosts.some(post => 
        post.selectedSchoolOption === newSchool && post.selectedCourseOption === selectedCourse
      );
      
      if (!isCurrentCourseValid) {
        setSelectedCourse('');
      }
    };
  
    const handleCourseChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      setSelectedCourse(e.target.value);
    };
  
    const uniqueSchoolOptions = React.useMemo(() => {
      const schoolSet = new Set(allPosts.map(post => post.selectedSchoolOption));
      return Array.from(schoolSet);
    }, [allPosts]);
  
    const uniqueCourseOptions = React.useMemo(() => {
      const relevantPosts = selectedSchool 
        ? allPosts.filter(post => post.selectedSchoolOption === selectedSchool)
        : allPosts;
      const courseSet = new Set(relevantPosts.map(post => post.selectedCourseOption));
      return Array.from(courseSet);
    }, [allPosts, selectedSchool]);
  
    return (
      <div>
        <h1>Hello, {user?.displayName}</h1>
        <select className="dropdownselect" value={selectedSchool} onChange={handleSchoolChange}>
          <option value="">School Select</option>
          {uniqueSchoolOptions.map((school, index) => (
            <option key={index} value={school}>{school}</option>
          ))}
        </select>
        <select className="dropdownselect" value={selectedCourse} onChange={handleCourseChange}>
          <option value="">Course Select</option>
          {uniqueCourseOptions.map((course, index) => (
            <option key={index} value={course}>{course}</option>
          ))}
        </select>
        <div className="rounded-lg">
         {selectedSchool === '' && selectedCourse === '' ? (
          <DefaultScreen />
        ) : (
          filteredPosts.map((post) => (
            <Post key={post.id} post={post} />
          ))
        )}
        </div>
      </div>
    );
  };



/**export const Main = () => {
  const [postsList, setPostsList] = useState<PostType[] | null>(null);
  const [selectedSchool, setSelectedSchool] = useState('');
  const [selectedCourse, setSelectedCourse] = useState('');



  const postsRef = collection(db, "posts");

  const getPosts = async () => {
    let postsQuery = query(postsRef);

    if (selectedSchool) {
      postsQuery = query(postsQuery, where("selectedSchoolOption", "==", selectedSchool));
    }

    if (selectedCourse) {
      postsQuery = query(postsQuery, where("selectedCourseOption", "==", selectedCourse));
    }

    const data = await getDocs(postsQuery);
    setPostsList(data.docs.map((doc) => ({...doc.data(), id: doc.id})) as PostType[]);
  }

  useEffect(() => {
    getPosts();
  }, [selectedSchool, selectedCourse])

  const handleSchoolChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedSchool(e.target.value);
  };

  const handleCourseChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCourse(e.target.value);
  };



  const uniqueSchoolOptions = React.useMemo(() => {
    if (!postsList) return [];
    
    const schoolOptionsSet = new Set<string>();
    postsList.forEach(post => {
      if (post.selectedSchoolOption) {
        schoolOptionsSet.add(post.selectedSchoolOption);
      }
    });
    return Array.from(schoolOptionsSet);
}, [postsList]);

const uniqueCourseOptions = React.useMemo(() => {
    if (!postsList) return [];

    const courseOptionsSet = new Set<string>();
    postsList.forEach(post => {
      if (post.selectedCourseOption){
        courseOptionsSet.add(post.selectedCourseOption);
      }
    });
    return Array.from(courseOptionsSet);
  }, [postsList]);
  





  return (
    <div>
      <select className="dropdownselect" value={selectedSchool} onChange={handleSchoolChange}>
        <option value="">School Select</option>
        {uniqueSchoolOptions.map((school, index) => (
          <option key={index} value={school}>{school}</option>
        ))}
      </select>
      <select className="dropdownselect" value={selectedCourse} onChange={handleCourseChange}>
        <option value="">Course Select</option>
        {uniqueCourseOptions.map((course, index) => (
          <option key={index} value={course}>{course}</option>
        ))}
      </select>
      <div className="rounded-lg">
        {postsList?.map((post) => (
          <Post key={post.id} post={post} />
        ))}
      </div>
    </div>
  );
};*/
