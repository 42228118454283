import './notfound.css'
import ErrorImage from '../images/error.png'
export const NotFound = () =>{
    return(
        <div className="notfound">
            <h1 className="color404">404</h1>
            <img src={ErrorImage} alt="error" className="errorimagesize"/>
            <h1>Page Not Found</h1>
            <p>We couldn't find the page you were looking for. <br /></p>
<p>You can return to our homepage by clicking <a href="/" className="linkcolor">here</a>.</p>
        </div>
    );
}