import './chapters.css'
import PlanoEast from '../../images/homepage.png'
import PlanoSenior from '../../images/planosenior.png'

export const Chapters = () =>{
    return(
        <div>
            <h1 className="gradient-text-chapters">Texas Chapters</h1>
            <div className="row-container-school">
                <div className="row-container-image">
                    <div className="row-container-image1">
                        <img src={PlanoEast} alt="plano east" className="chapter-image-size"/>
                    </div>
                    <div className="row-container-image2">
                        <img src={PlanoSenior} alt="plano senior" className="chapter-image-size" />
                    </div>
                </div>
                <div className="row-container-text">
                    <div className="row-container-description1">
                        <h2 className="chapter-school-name">Plano East Senior High School</h2>
                        <p>President: Ayan Mishra</p>
                        <p>Vice Presidents: Maha Rizvi, Swathi Parameshwar</p>
                    </div>
                    <div className="row-container-description2">
                        <h2 className="chapter-school-name">Plano Senior High School</h2>
                        <p>President: Benedict Premilan</p>
                        <p>Vice Presidents: Lindsey Zhao, Ashwini</p>
                    </div>
                </div>
            </div>
        </div>
    )
}