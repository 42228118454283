//import { getFirestore, collection, getDocs, addDoc, deleteDoc, doc } from 'firebase/firestore';
//import { db } from '../../config/firebase'; 
import {PostType} from './main'
import { auth } from '../../config/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import {useState} from 'react'
import './post.css';
import Microlink from "@microlink/react";
//import { ChevronDown, ChevronUp } from 'lucide-react';
import { useCollapse } from 'react-collapsed';
//import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
//import {db} from '../../config/firebase';
//import {useEffect} from 'react';
//{isExpanded ? <ChevronUp size={20} className="text-black"/> : <ChevronDown size={20} className="text-black"/>}

interface Props {
    post: PostType;
}
/**const handleDeleteOption = async (userId: string) => {
    try {
      await deleteDoc(doc(db, 'posts', userId));
      console.log("Document successfully deleted!");
      setOptions(options.filter(option => option.id !== optionId));
      if (selectedValue === posts.find(option => option.id === userId)?.value) {
        setSelectedValue('');
      }
    } catch (e) {
      console.error("Error removing document: ", e);
    }
  }; */

export const Post = ({ post }: Props) => {
    const [user] = useAuthState(auth);
    const [websiteEmbedError, setWebsiteEmbedError] = useState(false);
    const [pdfEmbedError, setPdfEmbedError] = useState(false);

    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

    const handleWebsiteEmbedError = () => {
        setWebsiteEmbedError(true);
    };

    const handlePdfEmbedError = () => {
        setPdfEmbedError(true);
    };

    const isValidUrl = (url: string): boolean => {
        try {
            new URL(url);
            return true;
        } catch {
            return false;
        }
    };

    return (
            
        
        <div className={`post-container border border-gray-200 rounded-xl overflow-hidden ${isExpanded ? '' : 'hover:bg-gray-200'}`}>
            <div 
                className="title p-4 bg-white cursor-pointer hover:bg-gray-200"
                {...getToggleProps()}
            >
                <div className="flex justify-between items-center">
                    <h2 className="text-xl font-semibold text-black">{post.title}</h2>
                    <button className="text-3xl bg-transparent border-none hover:bg-red-500">⋮</button>
                    
                </div>
                <p className="text-sm text-gray-600">{post.selectedCourseOption} | {post.selectedUnitOption}</p>
            </div>

            <div {...getCollapseProps()}>
                <div className="expanded-content p-4 bg-darkred">
                    <hr className="my-2" />
                    <p className="text-sm text-gray-600 text-left ml-12">Posted by: {post.username} | {post.selectedSchoolOption}</p>
                    <div className="post-body mt-4 text-black">
                        <p>{post.description}</p>

                        {!user ? (
          <>
          
          </>
        ) : (
          <>
          </>
        )}
                        
                    </div>
                <div className="flex flex-wrap gap-4">
                    {post.hyperlink && post.hyperlink.trim() !== "" && (
                        <div className="websitehyperlink mt-4">
                            {!websiteEmbedError && isValidUrl(post.hyperlink) && (
                                <Microlink 
                                    url={post.hyperlink} 
                                    onError={handleWebsiteEmbedError} 
                                    className="hover:shadow-lg transition-shadow duration-300 w-64 h-32 ml-12 bg-[#181818] rounded-xl"
                                />
                            )}
                            <p>
                                <a href={post.hyperlink} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                                    Hyperlink
                                </a>
                            </p>
                        </div>
                    )}

                    {post.pdflink && post.pdflink.trim() !== "" && (
                        <div className="pdflink mt-4">
                            
                            {!pdfEmbedError && isValidUrl(post.pdflink) && (
                                <Microlink url={post.pdflink} onError={handlePdfEmbedError} className="hover:shadow-lg transition-shadow duration-300 w-64 h-32 ml-12 rounded-xl" />
                            )}
                            <p>
                                <a href={post.pdflink} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                                    PDF Link
                                </a>
                            </p>
                        </div>
                    )}
                </div>
                </div>
            </div>
        </div>
    );
};













/**<div>
            <div>
            <select>
                <option value="ace">ace</option>
            </select>
            </div>
        
        <div className="post-container border border-gray-200 rounded-lg overflow-hidden">
            <div 
                className="title p-4 bg-white cursor-pointer hover:bg-white"
                onClick={toggleExpand}
            >
                <div className="flex justify-between items-center">
                    <h2 className="text-xl font-semibold text-black">{post.title}</h2>
                    {isExpanded ? <ChevronUp size={20} className="text-black"/> : <ChevronDown size={20} className="text-black"/>}
                </div>
                <p className="text-sm text-gray-600">{post.selectedCourseOption} | {post.selectedUnitOption}</p>
            </div>

            {isExpanded && (
                <div className="expanded-content p-4 bg-darkred">
                    <hr className="my-2" />
                    <p className="text-sm text-gray-600 text-left ml-12">Posted by: {post.username}</p>
                    <div className="post-body mt-4 text-black">
                        <p>{post.description}</p>
                    </div>
                <div className="flex flex-wrap gap-4">
                    {post.hyperlink && post.hyperlink.trim() !== "" && (
                        <div className="websitehyperlink mt-4">
                            {!websiteEmbedError && isValidUrl(post.hyperlink) && (
                                <Microlink 
                                    url={post.hyperlink} 
                                    onError={handleWebsiteEmbedError} 
                                    className="hover:shadow-lg transition-shadow duration-300 w-64 h-32 ml-12 bg-[#181818]"
                                />
                            )}
                        </div>
                    )}

                    {post.pdflink && post.pdflink.trim() !== "" && (
                        <div className="pdflink mt-4">
                            
                            {!pdfEmbedError && isValidUrl(post.pdflink) && (
                                <Microlink url={post.pdflink} onError={handlePdfEmbedError} className="hover:shadow-lg transition-shadow duration-300 w-64 h-32 ml-12" />
                            )}
                            <br />
                            <p>
                                <a href={post.pdflink} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                                    PDF File
                                </a>
                            </p>
                        </div>
                    )}
                </div>
                </div>
            )}
        </div>
        </div>**/










/**<iframe 
                                    src={post.pdflink} 
                                    title="PDF Embed" 
                                    width="100%" 
                                    height="500" 
                                    className="mt-2 border border-gray-300 rounded"
                                    onError={handlePdfEmbedError}
                                ></iframe> */


/**interface Props {
    post: PostType;
}



export const Post = (props: Props) => {
    const {post} = props;
    const [user] = useAuthState(auth);

    const [websiteEmbedError, setWebsiteEmbedError] = useState(false);
    const [pdfEmbedError, setPdfEmbedError] = useState(false);



        
    const handleWebsiteEmbedError = () => {
        setWebsiteEmbedError(true);
    };

    const handlePdfEmbedError = () => {
        setPdfEmbedError(true);
    };

    const isValidUrl = (url:string): boolean =>{
        try{
            new URL (url);
            return true;
        } catch {
            return false;
        }
    };



    return (
        <div className="post-container">
            <div className="title">
                <h2>{post.title}</h2>
                <p>Posted by: {post.username}</p>
                <p>{post.selectedCourseOption} | {post.selectedUnitOption}</p>
                <hr></hr>
            </div>
        
            <div className="post-body">
                <p>{post.description}</p>
            </div>

            {post.hyperlink && post.hyperlink.trim() !== "" && (
                <div className="websitehyperlink">
                    {!websiteEmbedError && isValidUrl(post.hyperlink) && (
                        <p>
                        <template>
                        <Microlink url={post.hyperlink} onError={handleWebsiteEmbedError} className="hover:shadow-lg transition-shadow duration-300 bg-[#181818]"/>
                        </template>
                        </p>
                    )}
                </div>
            
            )}

            {post.pdflink && post.pdflink.trim() !== "" && (
            <div className="pdflink">
                <p><a href={post.pdflink} target="_blank" rel="noopener noreferrer">PDF Link</a></p>
                {!pdfEmbedError && isValidUrl(post.pdflink) &&(
                <p><iframe src={post.pdflink} title="PDF Embed" width="600" height="750" onError={handlePdfEmbedError}></iframe></p>
                )}
            </div>
            )}

            

            
        </div>
            
    );
}**/











/**<div className="footer">
                <button onClick={hasUserLiked ? removeLike : addLike}> {hasUserLiked ? <>&#128078;</> : <>&#128077;</>}</button>
                {likes && <p>Likes: {likes?.length}</p>}
            </div>
            
            


            interface Like {
    likeId: string;
    userId:string;

}

            const[likes, setLikes] = useState<Like[] | null>(null);
            
            const likesRef = collection(db,"likes");

    const likesDoc = query(likesRef, where("postId", "==", post.id))

    const getLikes = async () =>{
        const data = await getDocs(likesDoc)
        setLikes(data.docs.map((doc) => ({userId: doc.data().userId, likeId: doc.id})));
    }

    const addLike = async () => {
        try{
        
        const newDoc = await addDoc(likesRef, {
            userId: user?.uid , postId: post.id
        });
        if(user){
        setLikes((prev) => prev ? [...prev, {userId: user.uid, likeId: newDoc.id}] : [{userId: user.uid, likeId: newDoc.id}]);
        }
    } catch (err){
        console.error(err);
    }
    };

    const removeLike = async() => {
        try{
            const likeToDeleteQuery = query(likesRef, where("postId", "==", post.id), where("userId", "==", user?.uid));
            const likeToDeleteData = await getDocs(likeToDeleteQuery)
            const likeId = likeToDeleteData.docs[0].id
            const likeToDelete = doc(db, "likes", likeId);
            await deleteDoc(likeToDelete);
            if (user){
                setLikes((prev) => prev && prev.filter((like) => like.likeId !== likeId));
            }
        } catch (err) {
            console.error(err);
        }
    }
    
    const hasUserLiked = likes?.find((like) => like.userId === user?.uid)
    
    useEffect(() => {
        getLikes();
    }, []);
            **/